import React from 'react';
import BreadCrumbs from '../utils/BreadCrumbs';
import { Input } from '../utils/inputs';
import { useDocumentWithExternalLinkCreate } from './utils/useDocumentWithExternalLinkCreate';

export function CreateDocumentWithExternalLinkForm() {
    const { handleChange, handleSubmit, values } = useDocumentWithExternalLinkCreate();
    return (
        <>
            <BreadCrumbs path="/documents/create" />
            <h3>Add Document</h3>
            <form className="wfp-form BDS-upload-documents-form-container" onSubmit={handleSubmit}>
                <label htmlFor="document">
                    <span>File Link</span>
                    <Input name="externalLink" onChange={handleChange} value={values.externalLink} />
                </label>
                <label>
                    <span>Section</span>
                    <Input name="section" onChange={handleChange} value={values.section} />
                </label>
                <label>
                    <span>File Name</span>
                    <Input name="fileName" onChange={handleChange} value={values.fileName} />
                </label>
                <button className="wfp-btn--primary" type="submit">
                    Create Document
                </button>
            </form>
        </>
    );
}
