import { RemovalReason } from './steps/ReasonSelectStep';

export const mapRemovalReasonNameToDisplayName = (enumValue: RemovalReason) => {
    switch (enumValue) {
        case RemovalReason.ChangeInHoHFinancialData:
            return 'Change in HoH financial data';
        case RemovalReason.ReversalOfPayment:
            return 'Reversal of payment';
        case RemovalReason.ErrorInOriginalIntendedAssistanceFileLoadedToBB:
            return 'Error in original intended assistance file loaded to BB';
        case RemovalReason.IncorrectTaxId:
            return 'Incorrect Tax ID';
        case RemovalReason.Other:
            return 'Other';
    }
};
