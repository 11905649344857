import React, { FC } from 'react';
import styled from 'styled-components';

import { mapRemovalReasonNameToDisplayName } from '../utils';

export interface ReasonSelectStepProps {
    selectedReason: RemovalReason;
    setSelectedReason: (reason: RemovalReason) => void;
}

export enum RemovalReason {
    IncorrectTaxId = 'IncorrectTaxId',
    ReversalOfPayment = 'ReversalOfPayment',
    ChangeInHoHFinancialData = 'ChangeInHoHFinancialData',
    ErrorInOriginalIntendedAssistanceFileLoadedToBB = 'ErrorInOriginalIntendedAssistanceFileLoadedToBB',
    Other = 'Other',
}

export const ReasonSelectStep: FC<ReasonSelectStepProps> = ({ selectedReason, setSelectedReason }) => {
    return (
        <StyledDiv>
            {Object.keys(RemovalReason).map((reason) => (
                <StyledLabel key={reason}>
                    <input
                        checked={selectedReason === reason}
                        name={reason}
                        onChange={() => setSelectedReason(reason as RemovalReason)}
                        type="radio"
                    />{' '}
                    {mapRemovalReasonNameToDisplayName(reason as RemovalReason)}
                </StyledLabel>
            ))}
        </StyledDiv>
    );
};

const StyledLabel = styled.label`
    font-weight: normal;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
