import { useDispatch } from 'react-redux';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { IsNotEmpty, IsString, IsUrl, validateOrReject } from 'class-validator';

import { useNavigate } from '@wfp-common/hooks/useNavigate';

import { errorAction } from '../../utils/notifications';
import { createDocumentWithExternalLink } from '../../apiClient';

export class ExternalLinkClassValidator {
    @IsString()
    @IsNotEmpty()
    @IsUrl({ protocols: ['http', 'https'], require_valid_protocol: true })
    externalLink: string;
}

interface ReturnObject {
    values: {
        section: string;
        fileName: string;
        externalLink: string;
    };

    handleChange(event: ChangeEvent<HTMLInputElement>): void;

    handleSubmit(event: FormEvent<HTMLFormElement>): void;
}

export function useDocumentWithExternalLinkCreate(): ReturnObject {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        section: '',
        fileName: '',
        externalLink: '',
    });
    const dispatch = useDispatch();
    const handleChange = useCallback((event) => {
        event.persist();
        setValues((state) => ({ ...state, [event.target.name]: event.target.value }));
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!values.fileName) {
            dispatch(errorAction('File name can not be empty'));
            return;
        }

        const linkValidator = new ExternalLinkClassValidator();
        linkValidator.externalLink = values.externalLink;

        try {
            await validateOrReject(linkValidator);
        } catch (e) {
            dispatch(errorAction('File link needs to be valid URL'));
            return;
        }

        try {
            await createDocumentWithExternalLink(values);
            navigate('/documents');
        } catch (error) {
            dispatch(errorAction('Could not upload file'));
        }
    };
    return {
        values,
        handleChange,
        handleSubmit,
    };
}
