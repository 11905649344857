import { DurationInputArg1, DurationInputArg2 } from 'moment';

import Action from '../action';
import { getAppConfig } from '../apiClient';
import { UaopFlags } from '../beneficiaries/fileUploadPages/DryRunDialog';
import { getAppConfig as getAppPartnerConfig } from '../partnerPanel/apiClient';
import { ProductTabs } from '../products/helpers';
import { countries } from '../utils/countries';
import { UserStatuses } from '../utils/users';
import { windowStorage } from '../windowStorage';

export type CustomExampleFilePerFlag = Record<UaopFlags, string>;

export interface SingleCategoryConfig {
    category: string;
    metaCategory: string;
    orderInMetaCategory: number;
    recommendedFlagForDeduplication?: UaopFlags;
    allowedDeduplicationFlags: UaopFlags[];
    allowedCategoriesForSharedDeduplication: string[];
    recommendedCategoryForSharedDeduplication?: string;
    deduplicationStatsInfo: string;
    isEnterpriseCategory?: boolean;
    customExampleFilePerFlag?: CustomExampleFilePerFlag;
    readonly: boolean;
    skipInterCategoryAndAssistedHouseholdExclusion: boolean;
}

export interface SingleMetaCategory {
    name: string;
    abbreviation: string;
    order: number;
}

export interface Permission {
    permissionName: string;
    metadata: {
        displayText: string;
        explanation: string;
        requiredPermissions: string[];
        section: string;
    };
}

export enum BeneficiaryPrimaryIdentifierType {
    TAX_ID = 'TAX_ID',
    COMMON_IDENTIFIER = 'COMMON_IDENTIFIER',
}

export const BeneficiaryPrimaryIdentifierToShow: Record<BeneficiaryPrimaryIdentifierType, string> = {
    COMMON_IDENTIFIER: 'Common Identifier',
    TAX_ID: 'Tax ID',
};

export const BeneficiaryPrimaryIdentifierAbbreviation: Record<BeneficiaryPrimaryIdentifierType, string> = {
    COMMON_IDENTIFIER: 'CID',
    TAX_ID: 'TID',
};

export class AppConfigState {
    entitlementsConfig: EntitlementCategoriesConfig;
    sapConfig: SapConfig;
    entitlementCurrencyConfig: EntitlementCurrencyConfig;
    paymentConfig: PaymentConfig;
    country: countries;
    location: { isActive: boolean };
    timeZone: string;
    permissions: {
        [key: string]: Permission[];
    };
    initialTransactionDate: string;
    project: ProjectType;
    productManagementConfig: { maxPriceValue: number; productsActiveTab: ProductTabs; priceSchedulingEnabled: boolean };
    userStatuses: {
        allStatus: UserStatuses[];
        managerStatus: UserStatuses[];
        vendorStatus: UserStatuses[];
        vendorUserStatus: UserStatuses[];
        partnerStatus: UserStatuses[];
        partnerUserStatus: UserStatuses[];
    };
    organizations: string[];
    beneficiariesImportMapping: Record<number, string>;
    removeEntitlementsImportMapping: Record<number, string>;
    beneficiaryPrimaryIdentifierType: BeneficiaryPrimaryIdentifierType;
    supportEmail: string;
    hideBeneficiarySupportTab: boolean;
    documentsUseExternalLinks: boolean;

    constructor(appConfig: AppConfigState) {
        this.entitlementsConfig = appConfig.entitlementsConfig;
        this.sapConfig = appConfig.sapConfig;
        this.entitlementCurrencyConfig = appConfig.entitlementCurrencyConfig;
        this.paymentConfig = appConfig.paymentConfig;
        this.country = appConfig.country;
        this.location = appConfig.location;
        this.timeZone = appConfig.timeZone;
        this.permissions = appConfig.permissions;
        this.initialTransactionDate = appConfig.initialTransactionDate;
        this.project = appConfig.project;
        this.userStatuses = appConfig.userStatuses;
        this.productManagementConfig = appConfig.productManagementConfig;
        this.organizations = appConfig.organizations;
        this.beneficiariesImportMapping = appConfig.beneficiariesImportMapping;
        this.removeEntitlementsImportMapping = appConfig.removeEntitlementsImportMapping;
        this.beneficiaryPrimaryIdentifierType = appConfig.beneficiaryPrimaryIdentifierType;
        this.supportEmail = appConfig.supportEmail;
        this.hideBeneficiarySupportTab = appConfig.hideBeneficiarySupportTab;
        this.documentsUseExternalLinks = appConfig.documentsUseExternalLinks;
    }
}

export class SapConfig {
    isSapActive: boolean;
    sapDocumentChainMinimumAmount: string;
    maxPOnumber: number;

    constructor(isSapActive = false, sapDocumentChainMinimumAmount = '100', maxPOnumber = 20) {
        this.isSapActive = isSapActive;
        this.sapDocumentChainMinimumAmount = sapDocumentChainMinimumAmount;
        this.maxPOnumber = maxPOnumber;
    }
}

export class EntitlementCategoriesConfig {
    categories: string[];
    categoriesToCategoriesFullNames: { [name: string]: string };
    categoriesToDeduplicationFlags: { [name: string]: UaopFlags[] };
    categoryConfig: SingleCategoryConfig[];
    metaCategoryConfig: SingleMetaCategory[];

    constructor(
        categories: string[] = [],
        categoriesToCategoriesFullNames: { [name: string]: string } = {},
        categoriesToDeduplicationFlags: { [name: string]: UaopFlags[] } = {},
        categoryConfig: SingleCategoryConfig[] = []
    ) {
        this.categories = categories;
        this.categoriesToCategoriesFullNames = categoriesToCategoriesFullNames;
        this.categoriesToDeduplicationFlags = categoriesToDeduplicationFlags;
        this.categoryConfig = categoryConfig;
    }
}

export interface PredefinedCurrency {
    name: string;
    fractionDigits: number;
}

export class EntitlementCurrencyConfig implements PredefinedCurrency {
    name: string;
    fractionDigits: number;

    constructor(name = '', fractionDigits = 3) {
        this.name = name;
        this.fractionDigits = fractionDigits;
    }
}

export class PaymentConfig {
    initialPeriodEnd: string;
    duration: PaymentDuration;
    mergeArraysSize: string;
}

export interface PaymentDuration {
    amount: DurationInputArg1;
    unit: DurationInputArg2;
}

export enum ProjectType {
    default = '/',
    dhaka = '/dhaka/',
}

export const projectTypeNaming = {
    [ProjectType.dhaka]: 'DHAKA',
};

export const AppConfigActionTypes = {
    loadAppConfig: 'AppConfig.loadAppConfig',
    loadProjectType: 'AppConfig.loadProjectType',
    changeProductTab: 'AppConfig.changeProductTab',
};

export const AppConfigActionCreators = {
    loadApplicationConfig(isVendor?: boolean, isPartner?: boolean) {
        let getConfig;

        if (isPartner) {
            getConfig = getAppPartnerConfig;
        } else {
            getConfig = getAppConfig;
        }
        return (dispatch) => {
            return getConfig().then((res) => {
                dispatch({
                    type: AppConfigActionTypes.loadAppConfig,
                    payload: res,
                });
            });
        };
    },
    changeProjectType(projectType) {
        return (dispatch) => {
            dispatch({
                type: AppConfigActionTypes.loadProjectType,
                payload: projectType,
            });
            windowStorage.setItem('projectType', projectType);
        };
    },

    changeProjectTypeBasedOnUrl() {
        return (dispatch) => {
            const search = new URLSearchParams(window.location.search);
            const projectType = atob(search.get('p'));
            dispatch({
                type: AppConfigActionTypes.loadProjectType,
                payload: projectType,
            });
            windowStorage.setItem('projectType', projectType);
        };
    },
};

export function appConfigReducer(
    state: AppConfigState = new AppConfigState({
        entitlementsConfig: new EntitlementCategoriesConfig(),
        sapConfig: new SapConfig(),
        entitlementCurrencyConfig: new EntitlementCurrencyConfig(),
        paymentConfig: new PaymentConfig(),
        country: window.location.href.includes('bgd') ? countries.BANGLADESH : countries.JORDAN,
        location: { isActive: false },
        timeZone: 'utc',
        permissions: {},
        initialTransactionDate: '',
        project: getProjectUrl(),
        userStatuses: {
            allStatus: [],
            managerStatus: [],
            vendorStatus: [],
            vendorUserStatus: [],
            partnerStatus: [],
            partnerUserStatus: [],
        },
        productManagementConfig: {
            maxPriceValue: Number.MAX_SAFE_INTEGER,
            productsActiveTab: ProductTabs.Prices,
            priceSchedulingEnabled: false,
        },
        organizations: [],
        beneficiariesImportMapping: {},
        removeEntitlementsImportMapping: {},
        beneficiaryPrimaryIdentifierType: null,
        supportEmail: '',
        hideBeneficiarySupportTab: false,
        documentsUseExternalLinks: false,
    }),
    action: Action
) {
    switch (action.type) {
        case AppConfigActionTypes.loadAppConfig:
            return new AppConfigState({ ...action.payload, project: state.project });
        case AppConfigActionTypes.loadProjectType:
            return new AppConfigState({ ...state, project: action.payload });
        case AppConfigActionTypes.changeProductTab:
            return new AppConfigState({
                ...state,
                productManagementConfig: { ...state.productManagementConfig, productsActiveTab: action.payload },
            });
    }
    return state;
}

export function getProjectUrl(): ProjectType {
    return (windowStorage.getItem('projectType') as ProjectType) || ProjectType.default;
}
